import React from "react";
import {graphql, navigate} from "gatsby"
import {FormikErrors, useFormik} from "formik";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import {getUTMFromStorage} from "utils";
import {startSignUp} from "services/signup";
import {NEWSLETTER_HASHES, ROUTES_PL} from "config";
import SiteLayout from "components/layouts/SiteLayout";
import Section from "components/molecules/Section";
import appPictureTop from "assets/images/app-top.png";
import appPictureBottom from "assets/images/app-bottom.png";

// TODO: Extract locales

interface SignUpFormValues {
  first_name: string;
  last_name: string;
  email: string;
  agreement_terms_and_conditions: boolean;
  utm_source?: string;
  utm_campaign?: string;
  utm_medium?: string;
  location?: number | "";
  marketing: boolean;
}

const validate = (values: SignUpFormValues) => {
  const errors: FormikErrors<SignUpFormValues> = {}

  if (!values.first_name.trim().length) {
    errors.first_name = "To pole jest wymagane"
  }
  if (!values.last_name.trim().length) {
    errors.last_name = "To pole jest wymagane"
  }
  if (!values.email.trim().length) {
    errors.email = "To pole jest wymagane"
  }
  if (!values.agreement_terms_and_conditions) {
    errors.agreement_terms_and_conditions = "zgoda jest wymagana"
  }

  if (values.location === 0) {
    errors.location = "Województwo nie jest obsługiwane"
  }

  return errors
}

function AcceptTermsAndConditionsLabel() {
  return (
    <Typography>
      Przeczytałem/-am i akceptuję{" "}
      <a target="_blank" href="/regulamin.pdf">
        regulamin
      </a>{" "}
      i{" "}
      <a target="_blank" href="/polityka-prywatnosci-dokdok.pdf">
        politykę prywatności.
      </a>
    </Typography>
  );
}

export default function RegisterPage() {
  const theme = useTheme();

  const initialValues: SignUpFormValues = {
    first_name: "",
    last_name: "",
    email: "",
    agreement_terms_and_conditions: false,
    marketing: false,
    location: ""
  };

  const onSubmit = async ({location, ...values}: SignUpFormValues) => {
    try {
      const utm = getUTMFromStorage();
      await startSignUp({...values, ...utm});
      navigate(ROUTES_PL.confirmEmail);
    } catch (e) {
      alert("Wystąpił nieznany błąd. Prosimy o kontakt dok@dokdok.pl");
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
  });

  const disableRegistrationFields = Number(!!formik.values.location) === 0;

  return (
    <SiteLayout>
      <Section padding={{ xs: "60px 20px", md: "120px" }}>
        <Grid container>
            <Grid item md={6} sx={{ color: theme.palette.primary.main, display: { xs: "none", md: "flex", flexDirection: "column" } }}>
              <Box
                alt="app bottom"
                component="img"
                loading="lazy"
                src={appPictureTop}
                sx={{
                  width: "100%",
                  height: "auto",
                  maxWidth: "392px",
                  margin: "0 auto",
                  aspectRatio: "1",
                }}
              />
              <Box
                alt="app bottom"
                component="img"
                loading="lazy"
                src={appPictureBottom}
                sx={{
                  width: "100%",
                  height: "auto",
                  maxWidth: "392px",
                  margin: "0 auto",
                  aspectRatio: "1",
                }}
              />
            </Grid>
          <Grid item xs md={6}>
            <form
              onSubmit={formik.handleSubmit}
              autoComplete="on"
            >
              <Grid container spacing={4}>
                <Grid container item xs={12} justifyContent="center">
                  <Typography color="primary" variant="h1" align="center" sx={{ pb: 2 }}>
                    Zarejestruj się do przychodni
                  </Typography>
                  <Typography align="center">
                    Wypełnij poniższy formularz i ciesz się dostępem do lekarza ze
                    swojego smartfona!
                  </Typography>
                </Grid>
                <Grid container item spacing={2} justifyContent="center">
                  <Grid item container xs={12}>
                    <TextField
                      label="Gdzie teraz mieszkasz?"
                      name="location"
                      select
                      variant="outlined"
                      fullWidth
                      required
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.location}
                    >
                      <MenuItem value={1}>
                        Dolnośląskie
                      </MenuItem>
                      <MenuItem value={2}>
                        Łódzkie
                      </MenuItem>
                      <MenuItem value={3}>
                        Mazowieckie
                      </MenuItem>
                      <MenuItem value={0}>
                        Inne
                      </MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item container xs={12}>
                    <TextField
                      color="primary"
                      name="first_name"
                      autoComplete="given-name"
                      label="Imię"
                      type="text"
                      variant="outlined"
                      fullWidth
                      required
                      disabled={disableRegistrationFields}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.first_name}
                      error={formik.touched.first_name && !!formik.errors.first_name}
                      helperText={formik.touched.first_name && formik.errors.first_name}
                    />
                  </Grid>
                  <Grid item container xs={12}>
                    <TextField
                      color="primary"
                      name="last_name"
                      label="Nazwisko"
                      autoComplete="family-name"
                      type="text"
                      variant="outlined"
                      fullWidth
                      required
                      disabled={disableRegistrationFields}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.last_name}
                      error={formik.touched.last_name && !!formik.errors.last_name}
                      helperText={formik.touched.last_name && formik.errors.last_name}
                    />
                  </Grid>
                  <Grid item container xs={12}>
                    <TextField
                      color="primary"
                      name="email"
                      label="Adres e-mail"
                      type="email"
                      autoComplete="email"
                      variant="outlined"
                      fullWidth
                      required
                      disabled={disableRegistrationFields}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      error={formik.touched.email && !!formik.errors.email}
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Grid>
                  <Grid item container xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          name="agreement_terms_and_conditions"
                          onChange={formik.handleChange}
                          value={formik.values.agreement_terms_and_conditions}
                        />
                      }
                      label={<AcceptTermsAndConditionsLabel/>}
                    />
                  </Grid>
                  <Grid item container xs={12}>
                    <FormControlLabel
                      sx={{ alignItems: "flex-start" }}
                      control={
                        <Checkbox
                          color="primary"
                          name="marketing"
                          onChange={formik.handleChange}
                          value={formik.values.marketing}
                        />
                      }
                      label={
                      <>
                        <Typography>Zgoda na wysyłkę newslettera lub informacji marketingowych DokDok sp. z o.o.</Typography>
                        <Typography variant="subtitle2">Oznacza zgodę na przetwarzanie przez DokDok sp. z o.o. mojego adresu poczty elektronicznej i numeru telefonu w celu przesyłania mi informacji marketingowych dotyczących produktów i usług oferowanych przez DokDok sp. z o.o. W każdej chwili mogę wycofać udzieloną zgodę bez podawania przyczyny.</Typography>
                      </>
                      }
                    />
                  </Grid>
                  <Grid container item xs={12} justifyContent="center">
                    <FormControl margin="none">
                      <Button
                        color="secondary"
                        disabled={!(formik.isValid && formik.dirty)}
                        type="submit"
                        variant="contained"
                        size="large"
                      >
                        Zarejestruj się
                      </Button>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Dialog open={!!formik.errors.location}>
            <DialogTitle>Przepraszamy</DialogTitle>
            <DialogContent>DokDok jeszcze niedostępny w Twoim mieście? Powiedz nam skąd jesteś, a my poinformujemy Cię jak tylko pojawi się tam nasza przychodnia!</DialogContent>
            <DialogActions>
              <Button
                color="error"
                variant="contained"
                onClick={() => formik.setFieldValue("location", null)}
              >
                Anuluj
              </Button>
              <Button
                onClick={() => navigate(ROUTES_PL.newsletter + "#" + NEWSLETTER_HASHES.newUnit)}
                color="primary"
                variant="contained"
              >
                Powiadom mnie
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Section>
    </SiteLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["index"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
