import axios from "axios";
import { API_KEY } from "./request";

export interface ISignup {
  building_number: string;
  city: string;
  email: string;
  first_name: string;
  flat_number: string;
  last_name: string;
  password: string;
  pesel: string;
  phone: string;
  postal_code: string;
  street_name: string;
  unit_id: string;
  nurse_id: string;
  physician_id: string;
  agreement_decalaration: boolean;
  agreement_terms_and_conditions: boolean;
  declaration: number;
}

export interface StartSignupRequest {
  email: string;
  first_name: string;
  last_name: string;
}

export interface FinishSignupRequest {
  first_name: string;
  last_name: string;
  email: string;
  pesel: string;
  street_name: string;
  building_number: string;
  flat_number: string | null;
  city: string;
  postal_code: string;
  phone: string;
  password: string;
}

export interface Unit {
  id: string;
  address: string;
  city: string;
  postal_code: string;
  name: string;
  latitude: string;
  longitude: string;
  physician: {
    id: string;
    first_name: string;
    last_name: string;
    title: string
  };
  nurse: {
    id: string;
    first_name: string;
    last_name: string;
    title: string;
  };
}

export interface UnitProposalResponse {
  unit_proposals: {
    unit: Unit
  }[]
}

export async function startSignUp(signup: StartSignupRequest) {
  const { data } = await axios.post("/signup", { signup, api_key: API_KEY });

  return data;
}

export async function getSignUpUser(submitToken: string) {
  const { data } = await axios.get<StartSignupRequest>("/signup/me", {
    params: {
      token: submitToken,
      api_key: API_KEY,
    },
  });

  return data;
}

export async function finishUserSignUp(
  signup: FinishSignupRequest,
  token: string
) {
  const { data } = await axios.put("/signup/me", {
    signup,
    token,
    api_key: API_KEY,
  });

  return data;
}

export async function getUnits() {
  const { data } = await axios.get<UnitProposalResponse>(`/signup/unit_proposals?api_key=${API_KEY}`)

  return data
}

/**
 * DEPRECATED
 */
export function signup(
  signupData: ISignup,
  success: Function,
  failure: Function
) {
  axios
    .post("/signup", JSON.stringify({ signup: signupData, api_key: API_KEY }))
    .then((data) => success(data))
    .catch((reason) => failure(reason));
}

export function sendAppLink(to: string, success: Function, failure: Function) {
  axios
    .post("/signup/send_app_link", JSON.stringify({ to: to, api_key: API_KEY }))
    .then((data) => success(data))
    .catch((reason) => failure(reason));
}

export function getUnitProposals(success: Function, failure: Function) {
  axios
    .get(`/signup/unit_proposals?api_key=${API_KEY}`)
    .then((data) => success(data))
    .catch((reason) => failure(reason));
}
